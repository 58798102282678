var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',{staticClass:"main index"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.savePopup)}}},[_c('div',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('h1',{staticClass:"is-size-3"},[_c('b',[_vm._v("Settings")])])]),_c('div',{staticClass:"column is-10"},[_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('h3',{staticClass:"is-size-6"},[_vm._v("Setting")])]),_c('li',[_c('router-link',{attrs:{"to":{ name: "user" }}},[_c('h3',{staticClass:"is-size-6"},[_vm._v("User")])])],1),_c('li',[(_vm.$route.name != 'editUser')?_c('h3',{staticClass:"is-size-6 breadcrumb-item"},[_vm._v(" Create User ")]):_c('h3',{staticClass:"is-size-6 breadcrumb-item"},[_vm._v("Edit User")])])])])]),_c('div',{staticClass:"column is-2"},[_c('b-field',{attrs:{"grouped":"","position":"is-right"}},[_c('b-button',{attrs:{"type":"is-hcc","native-type":"submit","icon-left":"save"}},[_vm._v(" Save ")])],1)],1)]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns is-12"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Name")]),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"columns is-12"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Role")]),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-select',{model:{value:(_vm.user.user_type),callback:function ($$v) {_vm.$set(_vm.user, "user_type", $$v)},expression:"user.user_type"}},_vm._l((_vm.roles),function(role){return _c('option',{key:role.id,domProps:{"value":role.id}},[_vm._v(" "+_vm._s(role.name)+" ")])}),0)],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"columns is-12"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Email")]),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),(_vm.$route.name != 'editUser')?_c('div',{staticClass:"columns is-12"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Password")]),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"vid":"pConf1","name":"password","rules":"required|password:pConf2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"password","password-reveal":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),(_vm.$route.name != 'editUser')?_c('div',{staticClass:"columns is-12"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Password Confirmation")]),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"vid":"pConf2","name":"confirm password","rules":"required|password:pConf1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"password","password-reveal":""},model:{value:(_vm.user.confirmation),callback:function ($$v) {_vm.$set(_vm.user, "confirmation", $$v)},expression:"user.confirmation"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e()])]),(_vm.$route.name == 'editUser')?_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"grouped":"","position":"is-right"}},[_c('router-link',{attrs:{"to":{
                  path: ("/user/change-password/" + (_vm.$route.params.userId)),
                }}},[_c('b-button',{attrs:{"type":"is-hcc"}},[_vm._v("Change Password")])],1)],1)],1):_vm._e()])])])]}}],null,false,3961338359)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }